<template>
  <section>
    <h1 class="sr-only">Sponsored video</h1>
    <template v-if="videoCode">
      <YTPlayer
        :video-code="videoCode"
        autoplay
        class="fixed inset-0 w-full h-full"
        @end="pushToLobby"
        v-if="!isOurVideo"
      />
      <VideoComponent
        v-else-if="videoCode"
        ref="player"
        :src="videoCode"
        autoplay="true"
        controls
        @ended="pushToLobby"
        class="absolute top-0 left-0 w-full h-full object-contain --reset-flip"
      />
    </template>
    <v-btn
      color="red"
      dark
      class="fixed"
      style="right: 8px; bottom: 80px"
      @click="pushToLobby"
      >Skip</v-btn
    >
  </section>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import { GetterTypes } from "@/store"
import isVideo from "is-video"

import VideoComponent from "@/components/ui/VideoComponent/VideoComponent.vue"

export default Vue.extend({
  name: "GameEndEarly",
  components: {
    VideoComponent,
    YTPlayer: () => import("@/components/VideoPlayers/YTPlayer.vue")
  },
  computed: {
    ...mapGetters("auth", ["clientID"]),
    videoCode() {
      return this.$store.getters[GetterTypes.END_EARLY_VIDEO]?.code
    },
    isOurVideo() {
      return isVideo(this.videoCode)
    }
  },
  created() {
    if (!this.videoCode) {
      this.pushToLobby()
    }
  },
  methods: {
    async pushToLobby() {
      await this.$navigator.navigateTo(`/lobby/${this.clientID}`)
    }
  }
})
</script>
